<template>
  <div class="content-size m-content">
    <h3 class="text-center">お申込み&利用の流れ</h3>
    <div class="w-100 mt-15p">
      <div>
        <div class="flex flex-center">
          <div class="w-20 step">STEP 1</div>
          <h4 class="h4-black ms-15p">まずは、下記のお問い合わせフォームに必要な情報を入力し、お申し込みをお願いします。</h4>
        </div>
      </div>
    </div>
    <div class="w-100 mt-15p">
      <div>
        <div class="flex flex-center">
          <div class="w-20 step">STEP 2</div>
          <h4 class="h4-black ms-15p">お申し込み完了後の画面に、料金のお支払いについての案内が表示されますので、案内にそってサービス利用料金の決済を行います。</h4>
        </div>
      </div>
    </div>
    <div class="w-100 mt-15p">
      <div>
        <div class="flex flex-center">
          <div class="w-20 step">STEP 3</div>
          <h4 class="h4-black ms-15p">ご入力いただいたメールアドレス宛に、決済完了後、決済完了の確認メールが送信されますのでご確認をお願いします。</h4>
        </div>
      </div>
    </div>
    <div class="w-100 mt-15p">
      <div>
        <div class="flex flex-center">
          <div class="w-20 step">STEP 4</div>
          <h4 class="h4-black ms-15p">お申込みから3日以内に、スポルターズから今後の流れについてのご案内をメールでお送りします。</h4>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ApplicationFlow",
};
</script>
<style lang="scss">
.step {
  display: flex;
  width: 220px;
  height: 50px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  min-height: 35px;
  height: 60px;
  min-width: 120px;
  border-radius: 999px;
  color: rgb(41, 64, 155);
  background-color: rgb(255, 240, 0);
  @media screen and (max-width: 767px) {
    width: 90%;
  }
}
.flex-center {
  align-items: center;
  margin-bottom: 50px;
}
.m-content {
  h4 {
    width: 80%;
  }
  @media screen and (max-width: 767px) {
    .flex {
      flex-direction: column;
    }
  }
}
</style>
