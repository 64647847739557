<template class="wrapper">
  <div class="main-visual">
    <img class="sp w-100" src="../assets/SPORTERS_main001sp.webp" alt="朝活専門 腹筋ガチガチ オンラインフィットネス" />
    <img class="pc w-100" src="../assets/SPORTERS_main001.webp" alt="朝活専門 腹筋ガチガチ オンラインフィットネス" />
  </div>
  <!-- 『朝活×オンラインフィットネス』がよいわけ -->
  <MorningFitnessSection />
  <!-- 朝活でスポルターズが選ばれる理由 -->
  <SportersMorningActivityReasons />
  <!-- インストラクター -->
  <InstructorProfile />
  <!-- プログラム内容 -->
  <Program />
  <!-- こんな方におすすめ -->
  <RecommendedAudience />
  <!-- コース・料金 -->
  <CoursePricing />
  <!-- お申込み&利用の流れ -->
  <ApplicationFlow />
  <!-- お申込み・お問い合わせ -->
  <ContactForm />
</template>
<script>
import MorningFitnessSection from "./MorningFitnessSection.vue";
import SportersMorningActivityReasons from "./SportersMorningActivityReasons.vue";
import InstructorProfile from "./InstructorProfile.vue";
import Program from "./Program.vue";
import RecommendedAudience from "./RecommendedAudience.vue";
import CoursePricing from "./CoursePricing.vue";
import ApplicationFlow from "./ApplicationFlow.vue";
import ContactForm from "./ContactForm.vue";
// import MailSend from "./MailSend.vue";

export default {
  name: "LpBody",
  components: {
    MorningFitnessSection,
    SportersMorningActivityReasons,
    InstructorProfile,
    Program,
    RecommendedAudience,
    CoursePricing,
    ApplicationFlow,
    ContactForm,
  },
};
</script>
<style lang="scss" scoped>
.main-visual {
  margin-top: 98px;
  @media screen and (max-width: 767px) {
    margin-top: 65px;
  }
}
</style>
