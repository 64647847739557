<template>
  <div class="send">
    <div class="heading">
      <h1>お申し込み・お問い合わせ<br class="sp" />ありがとうございます。<br />続いて、プランに応じて<br class="sp" />決済を完了させてください。</h1>
      <div class="bill">オープニング価格は<br />今だけ！</div>
    </div>
    <div class="w-100">
      <p class="point-text flex jc-c mb-40">オープニング価格（一括払い）</p>
    </div>
    <div class="course-section">
      <div class="sec">
        <div class="course-size">
          <div>
            <h4 class="point-text flex jc-c mb-15p">ベーシックコース</h4>
            <p class="price">2,480</p>
            <div class="tryangle"></div>
            <p class="reprice">1,980</p>
          </div>
          <p class="sum-price">12か月一括払い（23,760円）</p>
          <div class="description">
            <ul>
              <li>限定20名！！</li>
            </ul>
          </div>
        </div>
        <a href="https://square.link/u/wXCUY4Nb">お支払い</a>
      </div>
      <div class="sec">
        <div class="course-size">
          <div>
            <h4 class="point-text flex jc-c mb-15p">オプションコース</h4>
            <p class="price">9,300</p>
            <div class="tryangle"></div>
            <p class="reprice">7,440</p>
          </div>
          <p class="sum-price">6か月一括払い（44,640円）</p>
          <div class="description">
            <ul>
              <li>個別相談により自分だけのトレーニング動画をご提供。</li>
            </ul>
          </div>
        </div>
        <a href="https://square.link/u/op5FF8Dq">お支払い</a>
      </div>
      <div class="sec">
        <div class="course-size">
          <div>
            <h4 class="point-text flex jc-c mb-15p">スペシャルコース</h4>
            <p class="price">31,000</p>
            <div class="tryangle"></div>
            <p class="reprice">24,800</p>
          </div>
          <p class="sum-price">3か月一括払い（74,400円）</p>
          <div class="description">
            <ul>
              <li>運動・栄養・休養の個別相談をご提供。</li>
              <li>１週間に１回、ZOOM面談とトレーニングやストレッチ動画をご提供します。</li>
            </ul>
          </div>
        </div>
        <a href="https://square.link/u/6Wt2Atjc">お支払い</a>
      </div>
    </div>
    <div class="w-100">
      <p class="point-text flex jc-c mb-40">通常価格（月々払い）</p>
    </div>
    <div class="course-section">
      <div class="sec default">
        <div class="course-size">
          <div>
            <h4 class="point-text flex jc-c mb-15p">ベーシックコース</h4>
            <p class="price default">2,480</p>
          </div>
        </div>
        <a href="https://square.link/u/l66IifJk">お支払い</a>
      </div>
      <div class="sec default">
        <div class="course-size">
          <div>
            <h4 class="point-text flex jc-c mb-15p">オプションコース</h4>
            <p class="price default">9,300</p>
          </div>
        </div>
        <a href="https://square.link/u/ahdIyKYb">お支払い</a>
      </div>
      <div class="sec default">
        <div class="course-size">
          <div>
            <h4 class="point-text flex jc-c mb-15p">スペシャルコース</h4>
            <p class="price default">31,000</p>
          </div>
        </div>
        <a href="https://square.link/u/uMN3ojfE">お支払い</a>
      </div>
    </div>
  </div>
  <a class="top" href="/">Topへ戻る</a>
</template>
<script>
export default {
  name: "MailSend",
};
</script>
<style lang="scss" scoped>
.send {
  max-width: 1100px;
  margin: 0 auto;
  margin-top: 198px;
  padding: 0 20px;
  @media screen and (max-width: 767px) {
    margin-top: 165px;
  }
  .course-section {
    margin-bottom: 100px;
  }
  .heading {
    position: relative;
  }
  .bill {
    width: 200px;
    font-size: 21px;
    position: absolute;
    background-color: #fff000;
    padding: 10px 35px;
    font-weight: bold;
    top: 0;
    left: 83%;
    rotate: 20deg;
    @media screen and (max-width: 767px) {
      left: initial;
      right: -32px;
      top: 166px;
    }
  }

  h1 {
    font-size: 32px;
    position: relative;
    z-index: 1;
    margin: 100px auto;
    text-align: center;
    font-family: "Noto Sans JP M";
    color: rgb(41, 64, 155);
    font-weight: bold;
    line-height: 50px;
    @media screen and (max-width: 767px) {
      font-size: 22px;
      line-height: 30px;
      margin: 20px auto 90px auto;
    }
  }
  .sec {
    width: 32%;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    .course-size {
      width: 100%;
      min-height: 355px;
      margin-bottom: 20px;
    }
    a {
      color: #28409c;
      text-decoration: none;
      display: block;
      width: 100%;
      background-color: #fef000;
      height: 55px;
      text-align: center;
      line-height: 55px;
      font-size: 19px;
      font-weight: bold;
      margin-bottom: 50px;
      &:hover {
        background-color: rgb(41, 64, 155);
        color: rgb(255, 255, 255);
      }
    }
  }
  .default {
    .course-size {
      min-height: initial;
    }
  }
}

.top {
  color: #28409c;
  border: 1px solid #28409c;
  text-decoration: none;
  display: block;
  width: 33vw;
  height: 55px;
  margin: 50px auto 100px auto;
  text-align: center;
  line-height: 55px;
  font-size: 19px;
  font-weight: bold;
  &:hover {
    background-color: rgb(41, 64, 155);
    border: 1px solid rgb(41, 64, 155);
    color: rgb(255, 255, 255);
  }
  @media screen and (max-width: 767px) {
    width: initial;
    margin: 20px;
  }
}
</style>
