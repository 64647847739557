<template>
  <div v-show="this.scrollY < 6400 && this.$route.path === '/'" class="sp-contact">
    <button v-scroll-to="'#contact-form'">今すぐ人生変える（申し込み）</button>
  </div>
  <div class="footer relative">
    <div class="footer-content">
      <img class="sporters-log" src="../../assets/18496.webp" alt="SPORTERS-log" />
    </div>
    <img class="absolute footer-img" src="../../assets/SPORTERS_ira010.webp" alt="観葉植物" />
    <p class="copy-right">Copyright © sporters All Rights Reserved.</p>
  </div>
</template>
<script>
export default {
  name: "LayoutFooter",
  data() {
    return {
      scrollY: 0,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrollY = window.scrollY;
    },
  },
};
</script>
<style lang="scss">
.sp-contact {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  text-align: center;
  height: 60px;
  z-index: 5;
  padding: 10px 0;
  display: none;
  button {
    font-weight: bold;
    display: inline-block;
    width: 90vw;
    height: 100%;
    padding: 0 50px;
    border: 0px;
    border-radius: 999px;
    color: rgb(255, 255, 255);
    background-color: rgb(41, 64, 155);
    line-height: 60px;
    font-size: 17px;
    cursor: pointer;
  }
  @media screen and (max-width: 767px) {
    display: block;
  }
}
.footer {
  margin-top: 100px;
  background-color: rgb(255, 240, 0);
  @media screen and (max-width: 1099px) {
    margin-top: 180px;
  }
}
.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sporters-log {
  width: 25%;
  max-width: 120px;
}
.footer-img {
  width: 200px;
  top: -253px;
  right: 10px;
  @media screen and (max-width: 1099px) {
    width: 120px;
    top: -150px;
    right: 10px;
  }
}
.copy-right {
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
