<template>
  <div class="content-size m-content">
    <h3 class="text-center">こんな方におすすめ</h3>
    <div class="recommend">
      <div>
        <img src="../assets/SPORTERS_maru001.webp" />
        <span>朝活で人生<br />変えたい人</span>
      </div>
      <div>
        <img src="../assets/SPORTERS_maru003.webp" />
        <span>朝の時間を健康の<br />ために使いたい人</span>
      </div>
      <div>
        <img src="../assets/SPORTERS_maru003.webp" />
        <span>夏に向けて体を<br />引きしめたい人</span>
      </div>
      <div>
        <img src="../assets/SPORTERS_maru004.webp" />
        <span>一度は腹筋<br />割りたい人</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "RecommendedAudience",
};
</script>
<style lang="scss">
.recommend-field {
  z-index: 1;
  top: 0;
  height: 100%;
  .flex {
    height: 100%;
    flex-wrap: wrap;
    align-content: center;
  }
  @media screen and (min-width: 768px) and (max-width: 1099px) {
    // top: 35%;
  }
  @media screen and (max-width: 767px) {
    // top: 20%;
    > div.recommend-text-top,
    div.recommend-text-bottom {
      height: 50%;
      flex-direction: initial;
      margin: 0;
    }
    .left {
      // margin-right: -1.5vw;
    }
    .right {
      // margin-left: -1.5vw;
    }
  }
}
.recommend-text {
  color: rgb(255, 255, 255);
}
.recommend-text-top {
  color: rgb(255, 255, 255);
  margin-bottom: 25%;
}
.recommend-text-bottom {
  color: rgb(255, 255, 255);
  margin-top: 33%;
}
.recommend {
  display: grid;
  margin: 20px auto 0 auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
  }
  div {
    position: relative;
    text-align: center;
    img {
      width: 100%;
    }
    span {
      width: 100%;
      position: absolute;
      display: block;
      top: 48%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
    }
  }
}
</style>
