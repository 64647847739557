<template>
  <div class="p-content bgc-sub">
    <div class="content-size pc">
      <div class="flex">
        <div class="w-50">
          <span class="h3-sub">プログラム内容</span>
          <p class="w-90 mt-15p custom-text">
            Zoom（WEB会議ツール）を利用したライブレッスン。インストラクターとリアルタイムにつながることで、まるでスタジオの最前列でレッスンを受けているような感覚に。
            <br />
            ご自身からのビデオを「オン」にすれば、画面越しにインストラクターから直接指導が受けられます。
          </p>
        </div>
        <div class="w-50 my-auto">
          <div class="relative">
            <img src="../assets/SPORTERS_004.webp" alt="プランクをする女性" class="w-100" />
            <img src="../assets/SPORTERS_ira008.webp" alt="Fitness Goodhealth" class="absolute w-70 ira-008" />
          </div>
        </div>
      </div>
    </div>
    <div class="content-size sp">
      <div class="w-100 text-center mb-40p">
        <span class="h3-sub">プログラム内容</span>
      </div>
      <div class="relative mb-15p">
        <img src="../assets/SPORTERS_004.webp" alt="プランクをする女性" class="w-100" />
        <img src="../assets/SPORTERS_ira008sp.webp" alt="Fitness Goodhealth" class="absolute w-40 ira-008sp" />
      </div>
      <p>
        Zoom（WEB会議ツール）を利用したライブレッスン。インストラクターとリアルタイムにつながることで、まるでスタジオの最前列でレッスンを受けているような感覚に。
        <br />
        ご自身からのビデオを「オン」にすれば、画面越しにインストラクターから直接指導が受けられます。
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProgramSection",
};
</script>
<style lang="scss">
.ira-008 {
  z-index: 1;
  left: -150px;
  bottom: -50px;
  @media screen and (min-width: 768px) and (max-width: 1099px) {
    display: none;
  }
}
.ira-008sp {
  z-index: 1;
  left: -12px;
  top: -50px;
}
</style>
