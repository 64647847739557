<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import "reset-css";
import Header from "./components/Layouts/Header.vue";
import Footer from "./components/Layouts/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss">
/* Noto Sans JP (font-weight R:400 M:500) */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500&display=swap");

/* レスポンシブ */
@media only screen and (max-width: 767px) {
  .pc {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  .sp {
    display: none;
  }
}

:root {
  --main: rgb(255, 240, 0);
  --sub: rgb(41, 64, 155);
  --font: rgb(0, 0, 0);
  --content: rgb(255, 255, 255);
}

#app {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
  color: var(--font);
  background-color: var(--content);
  line-height: 2em;
  text-align: justify;
  @media screen and (max-width: 767px) {
    line-height: 1.6em;
  }
}

.error-message {
  color: var(--main);
}

/*------------------------
content
------------------------*/
.content-size {
  max-width: 1100px;
  margin: 0 auto;
  @media screen and (min-width: 768px) and (max-width: 1099px) {
    margin: 0 auto;
  }
  @media screen and (max-width: 767px) {
    width: auto;
    margin: 0;
    padding: 0 15px;
  }
}
.m-content {
  margin: 100px auto;
  @media screen and (min-width: 768px) and (max-width: 1099px) {
    margin: 70px 20px;
  }
  @media screen and (max-width: 767px) {
    margin: 40px 0;
  }
}
.p-content {
  overflow-x: hidden;
  padding: 150px 0;
  /* Tablet */
  @media screen and (min-width: 768px) and (max-width: 1099px) {
    padding: 70px 20px;
  }
  @media screen and (max-width: 767px) {
    padding: 40px 0 70px 0;
  }
}
.contact-form::before {
  content: "";
  display: block;
  height: 60px;
  visibility: hidden;
}
.contact-form {
  max-width: 1100px;
  background-color: var(--sub);
  margin: 0 auto;
  padding: 150px 3%;
  @media screen and (min-width: 768px) and (max-width: 1099px) {
    width: 80%;
    margin: 0 auto;
    padding: 70px 3%;
  }
  @media screen and (max-width: 767px) {
    width: auto;
    margin: 0;
    padding: 40px 3%;
  }
}
.form-content {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  font-size: 18px;
  padding: 0 10px;
  border: 0;
  margin-top: 5px;
  background-color: var(--content);
  box-sizing: border-box;
}
.form-textarea {
  display: flex;
  align-items: center;
  width: 100%;
  height: 200px;
  font-size: 18px;
  padding: 10px 10px;
  border: 0;
  margin-top: 5px;
  background-color: var(--content);
  box-sizing: border-box;
  resize: none;
}
.form-comfirm {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  font-size: 18px;
  padding: 0 10px;
  border: 0;
  margin-top: 5px;
  color: var(--content);
  background-color: var(--sub);
  border-bottom: 3px solid var(--content);
  box-sizing: border-box;
}
.textarea-comfirm {
  display: flex;
  align-items: center;
  width: 100%;
  height: 200px;
  font-size: 18px;
  padding: 10px 10px;
  border: 0;
  color: var(--content);
  background-color: var(--sub);
  border-bottom: 3px solid var(--content);
  box-sizing: border-box;
  resize: none;
}
/*------------------------
Headings
------------------------*/
h2 {
  font-size: 35px;
  font-weight: 500;
  line-height: 50px;
  color: var(--sub);
  text-align: center;
  @media screen and (max-width: 1099px) {
    font-size: 20px;
    line-height: 30px;
  }
}
.h2-white {
  font-size: 35px;
  font-weight: 500;
  line-height: 50px;
  color: var(--content);
  text-align: center;
  @media screen and (max-width: 1099px) {
    font-size: 20px;
    line-height: 30px;
  }
}
.h2-sub {
  position: relative;
  font-size: 35px;
  font-weight: 500;
  line-height: 65px;
  color: var(--content);
  text-align: left;
  @media screen and (max-width: 1099px) {
    font-size: 26px;
    line-height: 45px;
  }
}
.h2-sub::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px; /* アンダーラインの位置を調整 */
  width: 100%;
  height: 4px; /* アンダーラインの太さを調整 */
  background-color: var(--main); /* カスタムプロパティで色を指定 */
}
h3 {
  font-size: 28px;
  font-weight: 500;
  line-height: 50px;
  color: var(--sub);
  @media screen and (max-width: 1099px) {
    font-size: 25px;
    line-height: 30px;
  }
}
.h3-white {
  font-size: 28px;
  font-weight: 500;
  line-height: 50px;
  color: var(--content);
  @media screen and (max-width: 1099px) {
    font-size: 25px;
    line-height: 30px;
  }
}
.h3-sub {
  font-size: 28px;
  font-weight: 500;
  line-height: 50px;
  color: var(--content);
  border-bottom: 4px solid var(--main);
  @media screen and (max-width: 1099px) {
    font-size: 25px;
    line-height: 30px;
  }
}
h4 {
  display: flex;
  align-items: center;
  font-size: 25px;
  font-weight: 500;
  line-height: 50px;
  color: var(--sub);
  @media screen and (max-width: 1099px) {
    font-size: 21px;
    line-height: 30px;
  }
}
.h4-white {
  font-size: 25px;
  font-weight: 500;
  line-height: 50px;
  color: var(--content);
  @media screen and (max-width: 1099px) {
    font-size: 21px;
    line-height: 30px;
  }
}
.h4-black {
  font-size: 25px;
  font-weight: 500;
  line-height: 50px;
  color: var(--font);
  @media screen and (max-width: 1099px) {
    font-size: 21px;
    line-height: 30px;
  }
}
.text-white {
  color: var(--content);
}
/*------------------------
background-color
------------------------*/
.bgc-main {
  background-color: var(--main);
}
.bgc-sub {
  color: var(--content);
  background-color: var(--sub);
}
/*------------------------
position
------------------------*/
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
/*------------------------
display
------------------------*/
.block {
  display: block;
}
.flex {
  display: flex;
}
/*------------------------
text-align
------------------------*/
.text-center {
  text-align: center;
}
/*------------------------
justify-content
------------------------*/
.jc-c {
  justify-content: center;
}
.jc-sb {
  justify-content: space-between;
}
.jc-sa {
  justify-content: space-around;
}

/*------------------------
padding
------------------------*/
.px-20p {
  padding: 0 20px;
}
/*------------------------
margin
------------------------*/
.mt-auto {
  margin-top: auto;
}
.mt-15p {
  margin-top: 15px;
}
.mt-25p {
  margin-top: 25px;
}
.mr-auto {
  margin-right: auto;
}
.mb-15p {
  margin-bottom: 15px;
}
.mb-40p {
  margin-bottom: 40px;
}
.ms-15p {
  margin-left: 15px;
}
.ms-auto {
  margin-left: auto;
}
.mr-15p {
  margin-right: 15px;
}
.my-15p {
  margin: 15px 0;
}
.my-auto {
  margin: auto 0;
}
.mx-auto {
  margin: 0 auto;
}
/*------------------------
width
------------------------*/
.w-100 {
  width: 100%;
}
.w-90 {
  width: 90%;
}
.w-80 {
  width: 80%;
}
.w-70 {
  width: 70%;
}
.w-60 {
  width: 60%;
}
.w-50 {
  width: 50%;
}
.w-45 {
  width: 45%;
}
.w-40 {
  width: 40%;
}
.w-30 {
  width: 30%;
}
.w-25 {
  width: 25%;
}
.w-20 {
  width: 20%;
}
.w-10 {
  width: 10%;
}
/*------------------------
Font Size
------------------------*/
label {
  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
}
.custom-text {
  line-height: 1.7em;
}

.wrapper {
  height: 100%;
  min-height: 100vh;
  position: relative; /*←相対位置*/
  box-sizing: border-box; /*←全て含めてmin-height:100vhに*/
}

footer {
  width: 100%;
  position: absolute; /*←絶対位置*/
  bottom: 0; /*下に固定*/
}
</style>
