<template>
  <div class="p-content bgc-sub">
    <div class="content-size jc-sb">
      <!-- 0段目 -->
      <div class="w-100 sp">
        <span class="h2-sub">朝活でスポルターズが</span>
        <br />
        <span class="h2-sub">選ばれる理由</span>
      </div>
      <!-- 1段目 -->
      <div class="w-100 flex relative">
        <img src="../assets/SPORTERS_ira003sp.webp" alt="ストレッチをする女性" class="w-40 absolute left-img sp" />
        <div class="w-50 pc">
          <span class="h2-sub">朝活でスポルターズが</span>
          <br />
          <span class="h2-sub">選ばれる理由</span>
        </div>
        <div class="box-section mt-15p ms-auto">
          <img class="box-img" src="../assets/SPORTERS_001.webp" alt="あり吉とのトレーニング風景" />
          <p class="font">インストラクター歴15年！</p>
          <div class="sp">
            <div class="flex">
              <p class="w-70">毎日をポジティブ有吉がライブします！</p>
              <img class="point-img" src="../assets/SPORTERS_ira006.webp" alt="注目！" />
            </div>
          </div>
          <span class="point font pc">
            毎日をポジティブに有吉がライブします！
            <img class="point-img" src="../assets/SPORTERS_ira006.webp" alt="注目！" />
          </span>
        </div>
      </div>
      <!-- 2段目 src/assets/SPORTERS_ira005.webp -->
      <div class="w-100 relative">
        <img src="../assets/SPORTERS_ira003.webp" alt="ストレッチをする女性" class="w-45 absolute left-img pc" />
        <!-- SP -->
        <div class="box-section mt-15p mr-auto sp">
          <img class="box-img" src="../assets/SPORTERS_002.webp" alt="準備体操も忘れずに！" />
          <div class="point">
            <p class="font">日中の代謝を上げられる</p>
            <span class="point font">
              ゴールデンタイム
              <img class="point-img-2" src="../assets/SPORTERS_ira006.webp" alt="注目！" />
            </span>
          </div>
        </div>
        <!-- PC -->
        <div class="box-section mt-25p box-position pc">
          <img class="box-img" src="../assets/SPORTERS_002.webp" alt="準備体操も忘れずに！" />
          <p class="font">日中の代謝を上げられる</p>
          <span class="point font">
            ゴールデンタイム！
            <img class="point-img-2" src="../assets/SPORTERS_ira006.webp" alt="注目！" />
          </span>
        </div>
        <img src="../assets/SPORTERS_ira005.webp" alt="Good Time" class="w-40 absolute right-img" />
      </div>
      <!-- 3段目 -->
      <div class="w-100 flex relative">
        <img src="../assets/SPORTERS_ira004sp.webp" alt="腹筋きたえて理想の体型に！" class="w-40 absolute left-bottom-img sp" />
        <img src="../assets/SPORTERS_ira004.webp" alt="腹筋きたえて理想の体型に！" class="w-70 absolute left-bottom-img pc" />
        <div class="box-section mt-15p ms-auto">
          <img class="box-img" src="../assets/SPORTERS_003.webp" alt="みんなで楽しくフィットネス！" />
          <div class="point sp">
            <p class="font">みんなでやるから</p>
            <span class="point-text font">続けられる！</span>
            <img class="point-img-3" src="../assets/SPORTERS_ira006.webp" alt="注目！" />
          </div>
          <span class="point pc">
            みんなでやるから続けられる！
            <img class="point-img-3" src="../assets/SPORTERS_ira006.webp" alt="注目！" />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SportersMorningActivityReasons",
};
</script>
<style lang="scss">
.box-section {
  width: 40%;
  @media screen and (max-width: 767px) {
    width: 60%;
  }
}
.box-position {
  margin-left: 40%;
  /* Tablet */
  @media screen and (min-width: 768px) and (max-width: 1099px) {
    margin-left: 35%;
  }
}
.box-img {
  width: 100%;
}
.left-img {
  left: -130px;
  bottom: 80px;
  /* Tablet */
  @media screen and (min-width: 768px) and (max-width: 1099px) {
    left: -80px;
    bottom: 80px;
  }
  @media screen and (max-width: 767px) {
    left: -15px;
    bottom: 0px;
  }
}
.left-bottom-img {
  left: -170px;
  bottom: 0px;
  /* Tablet */
  @media screen and (min-width: 768px) and (max-width: 1099px) {
    left: -70px;
    bottom: 10%;
  }
  @media screen and (max-width: 767px) {
    left: -5px;
    bottom: -60px;
  }
}
.right-img {
  right: -200px;
  bottom: 30%;
  /* Tablet */
  @media screen and (min-width: 768px) and (max-width: 1099px) {
    right: -80px;
    bottom: 40%;
    width: 35% !important;
  }
  @media screen and (max-width: 767px) {
    right: -15px;
    bottom: 50%;
  }
}
.font {
  font-size: 16px;
}
/*------------------------
point-img position
------------------------*/
.point {
  position: relative;
}
.point-img {
  z-index: 1;
  width: 50px;
  height: 50px;
  margin-left: 10px;
  /* PC */
  @media screen and (min-width: 1099px) {
    position: absolute;
    bottom: 8px;
    margin-left: 3px;
  }
  /* Tablet */
  @media screen and (min-width: 768px) and (max-width: 1099px) {
    position: absolute;
    bottom: 70px;
    margin-left: 195px;
  }
}
.point-img-2 {
  position: absolute;
  z-index: 1;
  width: 50px;
  bottom: 10px;
  margin-left: 55px;
}
.point-img-3 {
  position: absolute;
  z-index: 1;
  width: 50px;
  bottom: 5px;
  margin-left: 3px;
  @media screen and (max-width: 767px) {
    bottom: 15px;
    margin-left: 47px;
  }
}
</style>
