<template>
  <div>
    <h1>特定商取引法に基づく表記</h1>
    <table>
      <tr v-for="val in list" :key="val.title">
        <th>{{ val.title }}</th>
        <td class="mail" v-if="val.title === 'メールアドレス'"></td>
        <td v-else>{{ val.value }}</td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  name: "SpecificCommericial",
  data() {
    return {
      list: [
        { title: "販売業者", value: "SPORTERS（スポルターズ）" },
        { title: "運営統括責任者名", value: "有吉徹" },
        {
          title: "所在地",
          value: "岐阜市若宮町5丁目12番若宮ビル1階X-market内",
        },
        { title: "電話番号", value: "058-201-4700" },
        { title: "電話受付時間", value: "受付時間 9:00〜21:00（不定休）" },
        { title: "メールアドレス", value: "" },
        { title: "サイトURL", value: "https://ariyoshi.sporters.jp/" },
        { title: "商品の販売価格", value: "各商品詳細をご参照ください。" },
        { title: "商品代金以外の必要料金の説明", value: "消費税" },
        { title: "支払方法", value: "クレジットカード決済" },
        {
          title: "支払時期",
          value: "クレジットカード決済の場合、カード情報を入力し、購入を完了した時点で課金されます。",
        },
        {
          title: "返品に関する事項",
          value: "顧客理由による注文のキャンセルまたは変更は受け付けておりません。",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
h1 {
  text-align: center;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
  color: #4d4d4d;
  font-size: 35px;
  margin-bottom: 75px;
}
table {
  border: 1px solid #ccc;
  margin: 0 auto 250px auto;
  border-collapse: collapse;
  th {
    border: 1px solid #ccc;
    background-color: #f2f2f2;
    padding: 12px 0;
    color: #4d4d4d;
    font-size: 17px;
    letter-spacing: 0.05em;
    text-align: center;
    width: 35%;
    font-weight: bold;
  }
  td {
    border: 1px solid #ccc;
    padding: 12px 30px;
    font-size: 17px;
    letter-spacing: 0.05em;
  }
}
@media screen and (max-width: 767px) {
  table {
    width: 80%;
  }
  table th {
    display: block;
    width: 100%;
    border: none;
  }
  table td {
    border: none;
    display: block;
    text-align: center;
  }
}
.mail::after {
  content: "info@sporters.jp";
}
</style>
