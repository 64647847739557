<template lang="">
  <div class="container pc">
    <div class="flex jc-sb">
      <img class="illustration-img" src="../assets/SPORTERS_ira001.webp" alt="窓と植物" />
      <div class="text-area">
        <h2>
          『朝活×オンラインフィットネス』が
          <br />
          よいわけ
        </h2>
        <p class="text">朝活にオンラインフィットネスを取り入れることは、一日を有効に活用する絶好の方法です。早起きすることで、忙しい日常では見つけられない「自分だけの時間」を作り出すことができます。この時間を利用してオンラインフィットネスに取り組むことで、健康はもちろん、「気持ち」と「腹筋」を整え、一日のスタートをポジティブにスタートすることができます。</p>
      </div>
      <img class="illustration-img" src="../assets/SPORTERS_ira002.webp" alt="朝にオンラインフィットネスをする女性" />
    </div>
  </div>
  <div class="container sp">
    <h2>
      『朝活×オンラインフィットネス』が
      <br />
      よいわけ
    </h2>
    <p class="text">朝活にオンラインフィットネスを取り入れることは、一日を有効に活用する絶好の方法です。早起きすることで、忙しい日常では見つけられない「自分だけの時間」を作り出すことができます。この時間を利用してオンラインフィットネスに取り組むことで、健康はもちろん、「気持ち」と「腹筋」を整え、一日のスタートをポジティブにスタートすることができます。</p>
    <img class="w-100" src="../assets/SPORTERS_ira001sp.webp" alt="朝活でオンラインフィットネスを行う女性" />
  </div>
</template>

<script>
export default {
  name: "MorningFitnessSection",
};
</script>

<style lang="scss">
.container {
  margin-top: 150px;
  @media screen and (max-width: 1099px) {
    margin: 30px 0;
  }
}
.text-area {
  width: 54%;
  max-width: 800px;
}
.text {
  margin-top: 20px;
  margin-bottom: 150px;
  @media screen and (min-width: 768px) and (max-width: 1099px) {
    margin: 15px 15px;
  }
  @media screen and (max-width: 767px) {
    margin: 15px 15px;
  }
}
.illustration-img {
  width: 21%;
  margin: 0 0 auto 10px;
}
</style>
