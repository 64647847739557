<template>
  <div id="contact-form" class="contact-form">
    <h3 class="h3-white text-center">
      お申込み・お問い合わせ
      <span v-if="displayType === 2"><br />内容確認</span>
    </h3>
    <p class="text-white my-15p" v-if="displayType === 1">お申し込みを希望される方は、以下のお問い合わせフォームからお申し込みをお願いします。お申し込みの完了ページに料金のお支払いについて案内を記載していますので、案内にそってクレジットカード決済をしていただくようお願いします。</p>
    <!-- 入力画面 -->
    <div v-if="displayType === 1">
      <!-- 名前 -->
      <div class="w-100 mt-25p">
        <div class="flex">
          <div class="hissu">必須</div>
          <h4 class="h4-white">お名前</h4>
        </div>
        <input type="text" v-model="form.name" class="form-content" placeholder="山田 太郎" />
        <p class="error-message" v-if="validation">{{ message.name }}</p>
      </div>
      <!-- メールアドレス -->
      <div class="w-100 mt-25p">
        <div class="flex">
          <div class="hissu">必須</div>
          <h4 class="h4-white">メールアドレス</h4>
        </div>
        <input type="email" v-model="form.email" class="form-content" placeholder="sample@sporters.jp" />
        <p class="error-message" v-if="validation">{{ message.email }}</p>
      </div>
      <!-- 電話番号 -->
      <div class="mt-25p">
        <div class="flex">
          <div class="hissu">必須</div>
          <h4 class="h4-white">電話番号</h4>
        </div>
        <input type="text" v-model="form.tel" class="form-content" placeholder="0123456789（ハイフンなし）" />
        <p class="error-message" v-if="validation">{{ message.tel }}</p>
      </div>
      <!-- 内容 -->
      <div class="mt-25p">
        <div class="flex">
          <div class="hissu">必須</div>
          <h4 class="h4-white">内容</h4>
        </div>
        <div class="form-content">
          <label for="entry" class="mr-15p">
            <input type="radio" v-model="form.type" name="type" id="entry" value="1" />
            申し込み
          </label>
          <label for="question" class="mr-15p">
            <input type="radio" v-model="form.type" name="type" id="question" value="2" />
            質問
          </label>
          <label for="other" class="mr-15p">
            <input type="radio" v-model="form.type" name="type" id="other" value="3" />
            その他
          </label>
        </div>
        <p class="error-message" v-if="validation">{{ message.type }}</p>
      </div>
      <div v-if="detailForm">
        <!-- コース -->
        <div class="mt-25p">
          <div class="flex">
            <div class="hissu">必須</div>
            <h4 class="h4-white">コース</h4>
          </div>
          <select v-model="form.course" name="course" id="course" class="form-content">
            <option value="">選択してください</option>
            <option value="1">ベーシックコース</option>
            <option value="2">オプションコース</option>
            <option value="3">スペシャルコース</option>
          </select>
          <p class="error-message" v-if="validation">{{ message.course }}</p>
        </div>
        <!-- 年齢 -->
        <div class="mt-25p">
          <div class="flex">
            <div class="hissu">必須</div>
            <h4 class="h4-white">年齢</h4>
          </div>
          <select v-model="form.age" name="age" id="age" class="form-content">
            <option value="">選択してください</option>
            <option value="1">10代</option>
            <option value="2">20代</option>
            <option value="3">30代</option>
            <option value="4">40代</option>
            <option value="5">50代</option>
            <option value="6">60代〜</option>
          </select>
          <p class="error-message" v-if="validation">{{ message.age }}</p>
        </div>
        <!-- 職業 -->
        <div class="mt-25p">
          <div class="flex">
            <div class="hissu">必須</div>
            <h4 class="h4-white">職業</h4>
          </div>
          <input type="text" v-model="form.job" class="form-content" />
          <p class="error-message" v-if="validation">{{ message.job }}</p>
        </div>
        <!-- 性別 -->
        <div class="mt-25p">
          <div class="flex">
            <div class="hissu">必須</div>
            <h4 class="h4-white">性別</h4>
          </div>
          <select v-model="form.gender" name="gender" id="gender" class="form-content">
            <option value="">選択してください</option>
            <option value="1">男子</option>
            <option value="2">女子</option>
            <option value="3">選ばない</option>
          </select>
          <p class="error-message" v-if="validation">{{ message.gender }}</p>
        </div>
      </div>
      <div v-if="textArea">
        <div class="mt-25p">
          <div class="flex">
            <div class="hissu">必須</div>
            <h4 class="h4-white">お問い合わせ内容</h4>
          </div>
          <textarea name="text" id="text" v-model="form.text" class="form-textarea" placeholder="お問い合わせ内容を入力してください"></textarea>
          <p class="error-message" v-if="validation">{{ message.text }}</p>
        </div>
      </div>
      <button @click="checkValidate()" class="send-btn">確認</button>
    </div>
    <!-- 確認画面 -->
    <div v-if="displayType === 2">
      <!-- 名前 -->
      <div class="w-100 mt-25p">
        <div class="flex">
          <h4 class="h4-white">お名前</h4>
        </div>
        <p class="form-content">{{ form.name }}</p>
      </div>
      <!-- メールアドレス -->
      <div class="w-100 mt-25p">
        <div class="flex">
          <h4 class="h4-white">メールアドレス</h4>
        </div>
        <p class="form-content">{{ form.email }}</p>
      </div>
      <!-- 電話番号 -->
      <div class="mt-25p">
        <div class="flex">
          <h4 class="h4-white">電話番号</h4>
        </div>
        <p class="form-content">{{ form.tel }}</p>
      </div>
      <!-- 内容 -->
      <div class="mt-25p">
        <div class="flex">
          <h4 class="h4-white">内容</h4>
        </div>
        <div v-if="form.type === '1'">
          <p class="form-content">申し込み</p>
        </div>
        <div v-if="form.type === '2'">
          <p class="form-content">質問</p>
        </div>
        <div v-if="form.type === '3'">
          <p class="form-content">その他</p>
        </div>
      </div>
      <div v-if="detailForm">
        <!-- コース -->
        <div class="mt-25p">
          <div class="flex">
            <h4 class="h4-white">コース</h4>
          </div>
          <div v-if="form.course === '1'">
            <p class="form-content">ベーシックコース</p>
          </div>
          <div v-if="form.course === '2'">
            <p class="form-content">オプションコース</p>
          </div>
          <div v-if="form.course === '3'">
            <p class="form-content">スペシャルコース</p>
          </div>
        </div>
        <!-- 年齢 -->
        <div class="mt-25p">
          <div class="flex">
            <h4 class="h4-white">年齢</h4>
          </div>
          <div v-if="form.age === '1'">
            <p class="form-content">10代</p>
          </div>
          <div v-if="form.age === '2'">
            <p class="form-content">20代</p>
          </div>
          <div v-if="form.age === '3'">
            <p class="form-content">30代</p>
          </div>
          <div v-if="form.age === '4'">
            <p class="form-content">40代</p>
          </div>
          <div v-if="form.age === '5'">
            <p class="form-content">50代</p>
          </div>
          <div v-if="form.age === '6'">
            <p class="form-content">60代〜</p>
          </div>
        </div>
        <!-- 職業 -->
        <div class="mt-25p">
          <div class="flex">
            <h4 class="h4-white">職業</h4>
          </div>
          <p class="form-content">{{ form.job }}</p>
        </div>
        <!-- 性別 -->
        <div class="mt-25p">
          <div class="flex">
            <h4 class="h4-white">性別</h4>
          </div>
          <div v-if="form.gender === '1'">
            <p class="form-content">男子</p>
          </div>
          <div v-if="form.gender === '2'">
            <p class="form-content">女子</p>
          </div>
          <div v-if="form.gender === '3'">
            <p class="form-content">選ばない</p>
          </div>
        </div>
      </div>
      <div v-if="textArea">
        <div class="mt-25p">
          <div class="flex">
            <h4 class="h4-white">お問い合わせ内容</h4>
          </div>
          <textarea readonly class="form-textarea" placeholder="お問い合わせ内容をお書きください" v-model="form.text"> </textarea>
        </div>
      </div>
      <div class="flex jc-sa">
        <button class="send-btn" @click="back()">戻る</button>
        <button class="send-btn" @click="send()">送信</button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "ContactForm",
  data() {
    return {
      displayType: 1,
      detailForm: false,
      textArea: false,
      form: {
        name: "",
        email: "",
        tel: "",
        type: "",
        course: "",
        age: "",
        job: "",
        gender: "",
        text: "",
      },
      validation: false,
      emailPattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      telPattern: /^0\d{9,10}$/,
      message: {
        name: "",
        email: "",
        tel: "",
        type: "",
        course: "",
        age: "",
        job: "",
        gender: "",
        text: "",
      },
    };
  },
  watch: {
    // 「お申し込み」を選択した際に、詳細フォームを表示させる
    "form.type"(newValue) {
      if (newValue === "1") {
        // バリデーションメッセージの初期化
        this.message.text = "";
        // 詳細フォームを表示
        this.detailForm = true;
        // テキストエリアを非表示
        this.textArea = false;
      } else {
        // バリデーションメッセージの初期化
        this.message.course = "";
        this.message.age = "";
        this.message.job = "";
        this.message.gender = "";
        // 詳細フォームを非表示
        this.detailForm = false;
        // テキストエリアを表示
        this.textArea = true;
      }
    },
  },
  methods: {
    checkValidate() {
      // 各入力項目の入力チェック
      this.validation = this.nameValid(this.form.name);
      this.validation = this.emailValid(this.form.email);
      this.validation = this.telValid(this.form.tel);
      this.validation = this.typeValid(this.form.type);
      this.validation = this.courseValid(this.form.course);
      this.validation = this.ageValid(this.form.age);
      this.validation = this.jobValid(this.form.job);
      this.validation = this.genderValid(this.form.gender);
      this.validation = this.textValid(this.form.text);
      // メッセージの状態チェック
      this.messageCheck();
      // バリデーションがfalseになっているか確認
      if (this.validation === false) {
        this.displayType = 2;
      }
    },
    nameValid(name) {
      if (!name) {
        this.message.name = "名前を入力してください";
        return true;
      }
      this.message.name = "";
      return this.validation != false ? this.validation : (this.validation = false);
    },
    emailValid(email) {
      if (!email) {
        this.message.email = "メールアドレスを入力してください";
        return true;
      } else if (!this.emailPattern.test(email)) {
        this.message.email = "メールアドレスは正しい形式で入力してください。";
        return true;
      }
      this.message.email = "";
      return this.validation != false ? this.validation : (this.validation = false);
    },
    telValid(tel) {
      if (!tel) {
        this.message.tel = "電話番号を入力してください";
        return true;
      } else if (!this.telPattern.test(tel)) {
        this.message.tel = "正しい電話番号を入力してください";
        return true;
      }
      this.message.tel = "";
      return this.validation != false ? this.validation : (this.validation = false);
    },
    typeValid(type) {
      if (!type) {
        this.message.type = "内容を選択してください";
        return true;
      }
      this.message.type = "";
      return this.validation != false ? this.validation : (this.validation = false);
    },
    courseValid(course) {
      if (this.form.type == "1") {
        if (!course) {
          this.message.course = "コースを選択してください";
          return true;
        }
        this.message.course = "";
        return this.validation != false ? this.validation : (this.validation = false);
      }
    },
    ageValid(age) {
      if (this.form.type == "1") {
        if (!age) {
          this.message.age = "年齢を選択してください";
          return true;
        }
        this.message.age = "";
        return this.validation != false ? this.validation : (this.validation = false);
      }
    },
    jobValid(job) {
      if (this.form.type == "1") {
        if (!job) {
          this.message.job = "職業を入力してください";
          return true;
        }
        this.message.job = "";
        return this.validation != false ? this.validation : (this.validation = false);
      }
    },
    genderValid(gender) {
      if (this.form.type == "1") {
        if (!gender) {
          this.message.gender = "年齢を選択してください";
          return true;
        }
        this.message.gender = "";
        return this.validation != false ? this.validation : (this.validation = false);
      }
    },
    textValid(text) {
      if (this.form.type !== "1") {
        if (!text) {
          this.message.text = "お問い合わせ内容を選択してください";
          return true;
        }
        this.message.text = "";
        return this.validation != false ? this.validation : (this.validation = false);
      }
    },
    messageCheck() {
      // メッセージが残っていなかったらバリデーションをfalseにする
      for (const key in this.message) {
        if (this.message[key] != "") {
          this.validation = true;
          break;
        }
        this.validation = false;
      }
    },
    back() {
      this.displayType = this.displayType - 1;
    },
    //確認画面
    // メール送信
    async send() {
      let params = new URLSearchParams();
      // パラメーター追加
      params.append("name", this.form.name);
      params.append("email", this.form.email);
      params.append("tel", this.form.tel);
      params.append("type", this.form.type);
      params.append("course", this.form.course);
      params.append("age", this.form.age);
      params.append("job", this.form.job);
      params.append("gender", this.form.gender);
      params.append("text", this.form.text);
      // 非同期通信

      const rootUrl = process.env.NODE_ENV === "production" ? "https://ariyoshi.sporters.jp" : "http://sporters.localhost";

      axios
        .post(rootUrl + "/api/send.php", params)
        .then((res) => {
          if (res.data.result) {
            location.href = "/mail-send";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss">
.hissu {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  width: 10%;
  min-height: 30px;
  font-size: 20px;
  margin: 5px 10px 5px 0;
  color: rgb(41, 64, 155);
  background-color: rgb(255, 240, 0);
  @media screen and (max-width: 767px) {
    width: 25%;
  }
}

.send-btn {
  width: 35%;
  font-size: 20px;
  color: rgb(255, 255, 255);
  background-color: rgb(41, 64, 155);
  padding: 15px;
  border: 1px solid rgb(255, 255, 255);
  margin: 40px auto 0 auto;
  display: block;
  cursor: pointer;
  @media screen and (max-width: 767px) {
    width: 55%;
    font-size: 16px;
    margin: 30px auto 0 auto;
  }
}
.send-btn:hover {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(255, 255, 255);
  color: rgb(41, 64, 155);
  font-weight: bold;
}
</style>
