<template lang="">
  <div class="p-content bgc-main">
    <div class="content-size">
      <div class="relative">
        <h3 class="text-center mb-40p">コース・料金</h3>
        <img class="absolute ira009" src="../assets/SPORTERS_ira009.webp" alt="coffee" />
      </div>
      <img class="pa" src="../assets/SPORTERS_pa004.webp" alt="" />
      <div class="w-100">
        <p class="point-text flex jc-c mb-40">オープニング価格（一括払い）</p>
      </div>
      <div class="course-section">
        <div class="course-size special">
          <div>
            <h4 class="point-text flex jc-c mb-15p">ベーシックコース</h4>
            <p class="price">2,480</p>
            <div class="tryangle"></div>
            <p class="reprice">1,980</p>
          </div>
          <p class="sum-price">12か月一括払い（23,760円）</p>
          <div class="description">
            <ul>
              <li>限定20名！！</li>
            </ul>
          </div>
        </div>
        <div class="course-size special">
          <div>
            <h4 class="point-text flex jc-c mb-15p">オプションコース</h4>
            <p class="price">9,300</p>
            <div class="tryangle"></div>
            <p class="reprice">7,440</p>
          </div>
          <p class="sum-price">6か月一括払い（44,640円）</p>
          <div class="description">
            <ul>
              <li>個別相談により自分だけのトレーニング動画をご提供。</li>
            </ul>
          </div>
        </div>
        <div class="course-size special">
          <div>
            <h4 class="point-text flex jc-c mb-15p">スペシャルコース</h4>
            <p class="price">31,000</p>
            <div class="tryangle"></div>
            <p class="reprice">24,800</p>
          </div>
          <p class="sum-price">3か月一括払い（74,400円）</p>
          <div class="description">
            <ul>
              <li>運動・栄養・休養の個別相談をご提供。</li>
              <li>１週間に１回、ZOOM面談とトレーニングやストレッチ動画をご提供します。</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="w-100">
        <p class="point-text flex jc-c mb-40">通常価格（月々払い）</p>
      </div>
      <div class="course-section">
        <div class="course-size">
          <div>
            <h4 class="point-text flex jc-c mb-15p">ベーシックコース</h4>
            <p class="price default">2,480</p>
          </div>
        </div>
        <div class="course-size">
          <div>
            <h4 class="point-text flex jc-c mb-15p">オプションコース</h4>
            <p class="price default">9,300</p>
          </div>
        </div>
        <div class="course-size">
          <div>
            <h4 class="point-text flex jc-c mb-15p">スペシャルコース</h4>
            <p class="price default">31,000</p>
          </div>
        </div>
      </div>
      <p class="info">オンラインフィットネスは毎週月曜日と木曜日の5時30分開始6時終了</p>
      <a class="specific-commercial" href="/specific-commercial" target="_blank">特定商取引法に基づく表記</a>
      <span class="notes">※料金は全て税込</span>
      <button class="entry-btn" v-scroll-to="'#contact-form'">お申し込みは<br class="sp" />こちら</button>
    </div>
  </div>
</template>
<script>
export default {
  name: "CoursePricing",
};
</script>
<style lang="scss">
.mb-40 {
  margin-bottom: 40px;
}
.info {
  color: rgb(41, 64, 155);
  font-size: 18px;
  text-align: center;
  margin-bottom: 20px;
}
.ira009 {
  width: 20%;
  right: 0;
  bottom: -42px;
  @media screen and (max-width: 767px) {
    width: 30%;
    right: -15px;
  }
}
.p-content > .content-size {
  position: relative;
}
.pa {
  position: absolute;
  width: 300px;
  top: 8px;
  left: -40px;
  @media screen and (max-width: 767px) {
    width: 30vw;
    left: -2%;
    min-width: 30vw;
    top: 3vh;
  }
}
.point-text {
  font-size: 20px;
  text-align: center;
  align-items: center;
  color: rgb(255, 255, 255);
  background-color: rgb(41, 64, 155);
  border: 0;
  height: 50px;
  line-height: 50px;
  border-radius: 99px;
  @media screen and (max-width: 1099px) {
    font-size: 16px;
    line-height: 17px;
    height: 60px;
  }
}
.course-section {
  @media screen and (min-width: 767px) {
    display: flex;
    justify-content: space-between;
  }
}
.course-section {
  .course-size {
    border: 1px solid rgb(41, 64, 155);
    color: rgb(41, 64, 155);
    font-family: "Noto Sans JP";
    margin-bottom: 40px;
    @media screen and (min-width: 767px) {
      width: 32%;
    }
    > div {
      padding: 15px;
    }
    .point-text {
      font-size: 20px;
      height: 50px;
      line-height: 50px;
    }
    .price {
      font-size: 25px;
      font-weight: bold;
      text-align: center;
    }
    .price::before {
      content: "";
      position: absolute;
      background-color: rgb(41, 64, 155);
      width: 99px;
      height: 2px;
      rotate: 343deg;
      margin-top: 16px;
      margin-left: -9px;
    }
    .price.default::before {
      height: 0;
    }
    .price::after {
      content: "円";
      margin-left: 2px;
      font-size: 16px;
    }
    .reprice {
      font-size: 30px;
      font-weight: bold;
      text-align: center;
    }
    .reprice::before {
      content: "今だけ";
      margin-right: 2px;
      font-size: 16px;
    }
    .reprice::after {
      content: "円 / 月";
      margin-left: 2px;
      font-size: 16px;
    }
    .sum-price {
      background-color: #b4bcdd;
      text-align: center;
      font-size: 15px;
      font-weight: bold;
      height: 40px;
      line-height: 40px;
    }
    .description {
      padding: 5px 15px;

      li {
        position: relative;
        padding-left: 25px;
        font-size: 16px;
      }

      ul li::before {
        content: "▢";
        position: absolute;
        left: 0;
      }
      ul li::after {
        content: "";
        position: absolute;
        left: -2px;
        top: 1px;
        width: 17px;
        height: 11px;
        border-left: 2px solid;
        border-bottom: 2px solid;
        rotate: -50deg;
      }
    }
  }
  .special {
    background-color: rgb(255, 255, 255);
  }
}
.course {
  width: 100%;
  border-bottom: 1px solid rgb(41, 64, 155);
  padding: 10px 0;
}
.entry-btn {
  width: 35%;
  font-size: 20px;
  font-weight: bold;
  color: rgb(41, 64, 155);
  background-color: rgb(255, 240, 0);
  padding: 15px;
  border: 1px solid rgb(41, 64, 155);
  margin: 40px auto 0 auto;
  display: block;
  cursor: pointer;
  &:hover {
    background-color: rgb(41, 64, 155);
    color: rgb(255, 255, 255);
  }
  @media screen and (max-width: 767px) {
    width: 55%;
    font-size: 16px;
    margin: 30px auto 0 auto;
  }
}
.tryangle {
  background-color: rgb(41, 64, 155);
  height: 15px;
  width: 35px;
  clip-path: polygon(0 0, 50% 100%, 100% 0);
  margin: 10px auto;
}
.notes {
  font-size: 15px;
  color: rgb(41, 64, 155);
}
.specific-commercial {
  display: block;
  float: right;
}
</style>
