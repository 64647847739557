<template>
  <div class="header">
    <a href="/">
      <img class="header-img" src="../../assets/SPORTERS-logo.webp" alt="SPORTERS-logo" />
    </a>
    <div v-show="this.$route.path === '/'" class="header-nav">
      <button class="header-btn" v-scroll-to="'#contact-form'">今すぐ人生変える（申し込み）</button>
    </div>
  </div>
</template>
<script>
export default {
  name: "LayoutHeader",
};
</script>
<style>
.header {
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0px;
  background-color: #fff;
  width: 100%;
  z-index: 5;
  @media screen and (max-width: 767px) {
    height: 65px;
  }
}

.header-img {
  width: 300px;
  padding: 7px;
}

.header-nav {
  display: flex;
  align-content: center;
}

.header-btn {
  font-weight: bold;
  height: 50%;
  padding: 0 15px;
  border: 0px;
  border-radius: 999px;
  margin: auto 20px auto 0;
  color: rgb(255, 255, 255);
  background-color: rgb(41, 64, 155);
  cursor: pointer;
}
.header-btn:hover {
  color: rgb(255, 240, 0);
}

/*------------------------------
SP
------------------------------*/
@media only screen and (max-width: 767px) {
  /* ここにスマートフォンの画面サイズに適用したいCSSを記述します */
  .header-nav {
    display: none;
  }

  .header-img {
    width: 200px;
  }
}
</style>
