import { createApp } from "vue";
import App from "./App.vue";
import VueScrollTo from "vue-scrollto";
import { router } from "./router";

const app = createApp(App);

// VueScrollTo
app.use(VueScrollTo);

app.use(router).mount("#app");
