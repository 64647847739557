import { createRouter, createWebHistory } from "vue-router";
import LpBody from "./components/LpBody.vue";
import Complete from "./pages/Complete.vue";
import MailSend from "./components/MailSend.vue";
import SpecificCommericial from "./components/SpecificCommericial.vue";

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "index",
      component: LpBody,
      meta: {
        title: "腹筋ガチガチオンラインフィットネス｜SPORTERS(スポルターズ)",
        description: "気持ちと体を整える！岐阜市にあるSPORTERS(スポルターズ)の有吉による朝活×オンラインフィットネス！腹筋割りたい、朝から代謝をあげたい、自分の時間を大切にしたいと思っているかた、みんなで朝から腹筋きたえながらポジティブな時間を過ごしましょう！",
      },
    },
    {
      path: "/specific-commercial/",
      name: "SpecificCommericial",
      component: SpecificCommericial,
      meta: {
        title: "特定商取引法に基づく表記|SPORTERS(スポルターズ)",
        description: "気持ちと体を整える！岐阜市にあるSPORTERS(スポルターズ)の有吉による朝活×オンラインフィットネス！腹筋割りたい、朝から代謝をあげたい、自分の時間を大切にしたいと思っているかた、みんなで朝から腹筋きたえながらポジティブな時間を過ごしましょう！",
      },
    },
    {
      path: "/mail-send/",
      name: "MailSend",
      component: MailSend,
      meta: {
        title: "腹筋ガチガチオンラインフィットネス｜SPORTERS(スポルターズ)",
        description: "気持ちと体を整える！岐阜市にあるSPORTERS(スポルターズ)の有吉による朝活×オンラインフィットネス！腹筋割りたい、朝から代謝をあげたい、自分の時間を大切にしたいと思っているかた、みんなで朝から腹筋きたえながらポジティブな時間を過ごしましょう！",
      },
    },
    {
      path: "/complete",
      name: "complete",
      component: Complete,
      meta: {
        title: "腹筋ガチガチオンラインフィットネス｜SPORTERS(スポルターズ)",
        description: "気持ちと体を整える！岐阜市にあるSPORTERS(スポルターズ)の有吉による朝活×オンラインフィットネス！腹筋割りたい、朝から代謝をあげたい、自分の時間を大切にしたいと思っているかた、みんなで朝から腹筋きたえながらポジティブな時間を過ごしましょう！",
      },
    },
  ],
});
router.beforeEach((to) => {
  if (location.origin + to.path != "https://ariyoshi.sporters.jp/") {
    document.querySelector("meta[name='robots']").setAttribute("content", "noindex");
  }
});
