<template lang="">
  <div class="content-size m-content pc">
    <div class="flex jc-sb">
      <div class="w-30 relative">
        <h3 class="text-left">インストラクター</h3>
        <img class="w-80 absolute dumbbell-img" src="../assets/SPORTERS_ira007.webp" alt="ダンベルイラスト" />
      </div>
      <div class="w-70 flex">
        <p class="w-60 my-auto px-20p">
          46歳。岐阜県瑞穂市在住。福岡県福岡市出身
          <br />
          運動指導歴16年。
          <br />
          30歳でフィットネスの業界に飛び込み、大手スポーツクラブで下積み10年。
          <br />
          自身の躁うつ病の改善と共に、パーソナルトレーナーとして2017年に独立。
          <br />
          現在は岐阜県の健康寿命向上の為、運動・栄養・休養の指導を企業や学校等にも発信中！
        </p>
        <div class="w-40 relative">
          <img class="w-100" src="../assets/SPORTERS_staff_01.webp" alt="インストラクター 有吉 徹" />
          <p class="name">有吉 徹</p>
        </div>
      </div>
    </div>
  </div>
  <div class="sp content-size m-content">
    <h2>インストラクター</h2>
    <img src="../assets/SPORTERS_staff_01sp.webp" alt="インストラクター 有吉 徹" class="w-100 mt-15p" />
    <p class="name">有吉 徹</p>
    <p class="mt-15p">
      ４６歳。岐阜県瑞穂市在住。福岡県福岡市出身
      <br />
      運動指導歴１６年。
      <br />
      ３０歳でフィットネスの業界に飛び込み、大手スポーツクラブで下積み１０年。
      <br />
      自身の躁うつ病の改善と共に、パーソナルトレーナーとして２０１７年に独立。
      <br />
      現在は岐阜県の健康寿命向上の為、運動・栄養・休養の指導を企業や学校等にも発信中！
    </p>
  </div>
</template>
<script>
export default {
  name: "InstructorProfile",
};
</script>
<style lang="scss">
.text-left {
  text-align: left !important;
}
.dumbbell-img {
  bottom: 0px;
}
.name {
  color: var(--sub);
  text-align: center;
  font-size: 20px;
  position: relative;
  top: -20px;
  font-weight: 500;
}
</style>
