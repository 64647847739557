<template>
  <div class="wrapper">
    <div class="content-size m-content main-visual">
      <h2 class="text-center">お申込みありがとうございました。</h2>
      <h2 class="text-center">朝活で人生変えていきましょう！</h2>
    </div>
    <div class="flex jc-c">
      <a href="/" class="back-btn">Topへ戻る</a>
    </div>
  </div>
</template>
<script>
export default {
  name: "Complete",
};
</script>
<style lang="scss">
.back-btn {
  display: flex;
  align-items: center;
  font-weight: bold;
  height: 60px;
  padding: 0 30px;
  border: 0px;
  border-radius: 999px;
  color: rgb(255, 255, 255);
  background-color: rgb(41, 64, 155);
  text-decoration: none;
}
.back-btn:hover {
  color: rgb(255, 240, 0);
}
.main-visual {
  margin-top: 198px;
  @media screen and (max-width: 767px) {
    margin-top: 165px;
  }
}
</style>
